<template>
    <div>
        <div class="footer-tab" :class="{memberOne: members == 1}" v-show="isShow || show">
            <router-link :to="{path:'/',query :{ show: true}}">
                <p class="iconfont icon-shouye"></p>
                首页
            </router-link>
            <router-link :to="{path:'/PointsMall',query :{ show: true}}">
                <p class="iconfont icon-shangcheng"></p>
                积分商城
            </router-link>
            <router-link :to="{path:'/Member',query :{ show: true}}">
                <p class="iconfont icon-yonghu"></p>
                会员中心
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'navBar',
    props: {
        members: Number,
        isShow: Boolean
    },
    data() {
        return {
            show: false,
        }
    },
    mounted() {
        if(this.$route.query.show) {
           this.show = this.$route.query.show 
        }
        
    }
}
</script>
