import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import './assets/iconfont/iconfont.css'
import './assets/css/common.css'

Vue.config.productionTip = false;

// 地图配置
//配置安全密钥
window._AMapSecurityConfig = {
	securityJsCode: 'ec0a8afb988984ec17fb67e6ea573b2c' //*  安全密钥
}

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
