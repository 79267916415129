<template>
  <div class="home">
    <div class="Carousel">
      <swiper class="banner" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="item in carouselImage" :key="item.id">
          <img :src="item" />
        </swiper-slide>
        <div class="swiper-pagination pointer" slot="pagination"></div>
      </swiper>
    </div>
    <!-- 健康头条 -->
    <div class="healthy-Headlines" @click="toHealthyNews()">
      <div class="healthy-headlines-content">
          <div class="laba">
            <i class="iconfont icon-u65"></i>
          </div>
          <div>
            <span>健康</span>
          </div>
          <div class="Headlines">
            <span>头条</span>
          </div>
          <div class="content">
            <span>宅在家中 如何正确用眼？</span>
          </div>
          <div class="jiantou">
            <i class="iconfont icon-jinrujiantou"></i>
          </div>
      </div>
    </div>
    <!-- 功能导航 -->
    <div class="setts-container">
      <div class="setts-nav">
        <div class="jifen">
          <span class="first"></span>
          <span class="text">功能导航</span>
        </div>
      </div>
      <div class="common-content">
        <div @click="goMap()">
          <i class="iconfont icon-u112"></i>
          <p>来院路线</p>
        </div>
        <div @click="goVIP()">
          <i class="iconfont icon-u109"></i>
          <p>会员中心</p>
        </div>
        <div @click="goShop()">
          <i class="iconfont icon-u115"></i>
          <p>积分商城</p>
        </div>
        <div @click="goLine()">
          <i class="iconfont icon-u118"></i>
          <p>在线咨询</p>
        </div>
      </div>
      <div class="setts-nav">
        <div class="jifen">
          <span class="first"></span>
          <span class="text">积分好物</span>
        </div>
        <div class="more" @click="goMore()">
          查看更多...
        </div>
      </div>
      <div class="shop">
        <div class="shop-tips">
          <div class="shop-tips-content">
            <div class="shop-img">
              <img src="../assets/images/shop/u77.png" />
            </div>
            <div class="shop-title">定制保温杯</div>
            <div class="shop-money">
              <div class="money-info">
                <i class="iconfont icon-jinbi"></i>
                <span class="money">50</span>
              </div>
              <div class="exchange">兑换</div>
            </div>
          </div>
        </div>
        <div class="shop-tips">
          <div class="shop-tips-content">
            <div class="shop-img">
              <img src="../assets/images/shop/u94.png" />
            </div>
            <div class="shop-title">精品墨镜</div>
            <div class="shop-money">
              <div class="money-info">
                <i class="iconfont icon-jinbi"></i>
                <span class="money">150</span>
              </div>
              <div class="exchange">兑换</div>
            </div>
          </div>
        </div>
        <div class="shop-tips">
          <div class="shop-tips-content">
            <div class="shop-img">
              <img src="../assets/images/shop/u95.png" />
            </div>
            <div class="shop-title">华为智能耳机</div>
            <div class="shop-money">
              <div class="money-info">
                <i class="iconfont icon-jinbi"></i>
                <span class="money">50</span>
              </div>
              <div class="exchange">兑换</div>
            </div>
          </div>
        </div>
        <div class="shop-tips last">
          <div class="shop-tips-content">
            <div class="shop-img">
              <img src="../assets/images/shop/u96.png" />
            </div>
            <div class="shop-title">品牌山地自行车</div>
            <div class="shop-money">
              <div class="money-info">
                <i class="iconfont icon-jinbi"></i>
                <span class="money">180</span>
              </div>
              <div class="exchange">兑换</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- navBar组件 -->
    <nav-bar :isShow="true" />
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import NavBar from '../components/navBar.vue';
export default {
  name: "Home",
  components: {
    swiper,
    swiperSlide,
    NavBar,
  },
  data() {
    return {
      swiperOption: {
        autoplay: true,
        speed: 300,
        loop: true,
        isNavBar: false,
        pagination: {
          el: ".swiper-pagination", //分页器
          clickable: true,
        },
      },
      carouselImage: [
        require(`@/assets/images/home/u42.png`),
        require(`@/assets/images/home/u43.png`),
        require(`@/assets/images/home/u44.png`),
        require(`@/assets/images/home/u45.png`),
      ],
    };
  },
  created() {
    this.isNavBar = true;
  },
  methods: {
    toHealthyNews() {
      this.$router.push("/healthyHeadline");
    },
    goShop() {
      this.$router.push('/PointsMall');
    },
    goVIP() {
      this.$router.push({path:'/Member',query :{ show: true}});
    },
    goLine() {
      this.$router.push('/SignIn');
    },
    goMore() {
      this.$router.push('/PointsMall');
    },
    goMap() {
      this.$router.push('/map');
    }
  },
};
</script>

<style lang="less" scoped>
@import '../assets/css/home.less';
</style>
