import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // 会员中心
  {
    path: "/Member",
    name: "Member",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  // 积分商城
  {
    path: "/PointsMall",
    name: "PointsMall",
    component: () => import("../views/PointsMall.vue"),
  },
  // 健康头条
  {
    path: "/healthyHeadline",
    name: "healthyHeadline",
    component: () => import("../views/healthyHeadline.vue"),
  },
  // 待支付
  {
    path: "/toBePaid",
    name: "toBePaid",
    component: () => import("../views/toBePaid.vue"),
  },
  // 待发货
  {
    path: "/toBeShipped",
    name: "toBeShipped",
    component: () => import("../views/toBeShipped.vue"),
  },
  // 待收货
  {
    path: "/toBeReceived",
    name: "toBeReceived",
    component: () => import("../views/toBeReceived.vue"),
  },
  // 支付
  {
    path: "/pay",
    name: "pay",
    component: () => import("../views/pay.vue"),
  },
  // 签到
  {
    path: "/SignIn",
    name: "SignIn",
    component: () => import("../views/SignIn.vue"),
  },
  // 我的报名
  {
    path: "/MyRegistration",
    name: "MyRegistration",
    component: () => import("../views/MyRegistration.vue"),
  },
  // 我的地址
  {
    path: "/MyAdress",
    name: "MyAdress",
    component: () => import("../views/MyAdress.vue"),
  },
  // 我的档案
  {
    path: "/MyProfile",
    name: "MyProfile",
    component: () => import("../views/MyProfile.vue"),
  },
  // 就诊管理人
  {
    path: "/EncounterMgmt",
    name: "EncounterMgmt",
    component: () => import("../views/EncounterMgmt.vue"),
  },
  // 添加就诊管理人
  {
    path: "/addCustomer",
    name: "addCustomer",
    component: () => import("../views/addCustomer.vue"),
  },
  // 添加就诊管理人
  {
    path: "/map",
    name: "Map",
    component: () => import("../views/map.vue"),
  },
];

const router = new VueRouter({
  routes,
  // linkActiveClass: `active`,
  linkExactActiveClass: `active`,
});

export default router;
